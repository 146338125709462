<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { boolean, object, string } from 'zod';
    import { toTypedSchema } from '@vee-validate/zod';
    import { useField, useForm } from 'vee-validate';
    import FormField from '@/components/form-components/FormField.vue';
    import { hasValidationError } from '@/util/hasValidationError';
    import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
    import Button from 'primevue/button';
    import Password from 'primevue/password';
    import { computed, ref } from 'vue';
    import ErrorTag from '@/components/ErrorTag.vue';
    import { Navigation } from '@/router/navigation';
    import router from '@/router';
    import { getLogger } from '@/logger/logger';
    import { passwordRequirements } from '@/common/validation/password';
    import PasswordRequirements from '@/common/components/PasswordRequirements.vue';
    import CheckboxField from '@/components/form-components/CheckboxField.vue';
    import { useMarketQuery } from '@/composables/market';

    const props = defineProps<{
        email: string;
        token: string;
    }>();

    const decodedEmail = computed(() => decodeURIComponent(props.email));

    const { t } = useI18n();
    const logger = getLogger('PasswordActivateView');
    const { market } = useMarketQuery();

    const marketCode = computed(() => market?.value?.code.toLowerCase() ?? 'at');
    const agbUrl = computed(() => `https://www.containex.com/${marketCode.value}/de/nutzungsbedingungen`);

    const { lengthCheck, numberCheck, letterCheck, validatorFn } = passwordRequirements();

    const showError = ref(false);

    const schema = object({
        password: string()
            .trim()
            .refine(validatorFn, {
                message: t('FORM.PASSWORD_VALIDATION.ERROR'),
            }),
        passwordConfirm: string().refine(areSamePassword, {
            message: t('FORM.PASSWORD_CONFIRM_VALIDATION_TEXT'),
        }),
        acceptedAgb: boolean()
            .default(false)
            .refine((value) => value, {
                message: t('FORM.AGB_TEXT_VALIDATION_TEXT'),
            }),
    });

    const { handleSubmit, errors } = useForm({
        validationSchema: toTypedSchema(schema),
    });
    const { value: password } = useField<string>('password');
    const { value: passwordConfirm } = useField<string>('passwordConfirm');
    const { value: acceptedAgb } = useField<boolean>('acceptedAgb');

    const onSubmit = handleSubmit(async (values) => {
        showError.value = false;
        const authenticationAction = useAuthenticationAction();
        try {
            await authenticationAction.activateCustomer(
                decodedEmail.value,
                values.password,
                props.token,
                values.acceptedAgb
            );
            await router.push({ name: Navigation.ResetPasswordSuccess });
        } catch (error) {
            logger.error(error, 'Setting password failed');
            showError.value = true;
        }
    });

    const { isUserLoggedIn } = useAuthenticationQuery();

    function areSamePassword(value: string): boolean {
        return password.value === value;
    }
</script>

<template>
    <div v-if="!isUserLoggedIn" class="flex-container">
        <h2>{{ t('LOGIN.ACTIVATE_PASSWORD.TITLE') }}</h2>
        <p class="text">{{ t('LOGIN.ACTIVATE_PASSWORD.DESCRIPTION', { email: decodedEmail }) }}</p>
        <form class="form" @submit.prevent="onSubmit">
            <div>
                <FormField :label="t('FORM.PASSWORD')" :error-message="errors.password">
                    <Password
                        v-model="password"
                        input-id="password"
                        class="full-width"
                        :input-class="showError ? 'error-border' : 'full-width'"
                        :feedback="false"
                        :invalid="hasValidationError(errors.password)"
                    />
                </FormField>
                <PasswordRequirements
                    :letter-check="letterCheck"
                    :number-check="numberCheck"
                    :length-check="lengthCheck"
                ></PasswordRequirements>
                <FormField :label="t('FORM.PASSWORD_CONFIRM')" :error-message="errors.passwordConfirm">
                    <Password
                        v-model="passwordConfirm"
                        input-id="passwordConfirm"
                        class="full-width"
                        :input-class="showError ? 'error-border' : 'full-width'"
                        :feedback="false"
                        :invalid="hasValidationError(errors.passwordConfirm)"
                    />
                </FormField>
                <CheckboxField
                    v-model="acceptedAgb"
                    class="checkbox-agb"
                    data-testid="signup-form-checkbox-terms"
                    :binary="true"
                    :invalid="hasValidationError(errors.acceptedAgb)"
                    :error-message="errors.acceptedAgb"
                >
                    {{ t('FORM.AGB_TEXT.PRE') }}
                    <a :href="agbUrl" class="privacy-policy-link" target="_blank">
                        {{ t('FORM.AGB_TEXT.AGB') }}
                    </a>
                    {{ t('FORM.AGB_TEXT.POST') }}
                </CheckboxField>
            </div>
            <ErrorTag v-if="showError" :message="t('LOGIN.ACTIVATE_PASSWORD.ERROR_SUMMARY')" />
            <div>
                <Button type="submit" :label="t('LOGIN.ACTIVATE_PASSWORD.SAVE_BUTTON')" />
            </div>
        </form>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .checkbox-agb {
        margin-top: main.$spacing-4;
    }

    .flex-container {
        margin: auto;
        display: flex;
        flex-direction: column;
        background: main.$vt-c-white;
        padding: main.$spacing-6;
        max-width: 448px;

        :deep(.error-border) {
            border-color: main.$color-error-red;
        }
    }

    .text {
        margin-bottom: 0;
    }

    .form {
        background: main.$vt-c-white;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: main.$spacing-6;
    }
</style>
